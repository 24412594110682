@font-face {
  font-family: "Sora";
  src: url("/public/fonts/sora/Sora-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "SourcePro";
  src: url("/public/fonts/sourcepro/sourcepro.ttf") format("truetype");
}

:root {
  --koii-blue: #ecfffe;
  --koii-mint: #5ed9d1;
  --koii-white: #171753;
  --koii-mint: #8989c7;
  --background-image: url("/public/images/welcome_page_bg.svg");
  --background-image-md: url("/public/images/Desktop L (1).svg");
  --background-image-sm: url("/public/images/Phone L.svg");
  --dashboard-icon: black;
}

[data-theme="neutral"] {
  --koii-blue: #ecfffe;
  --koii-mint: #5ed9d1;
  --koii-white: #171753;
  --koii-button-white: #171753;
  --koii-mint: #8989c7;
  --background-image: url("");
  --background-image-md: url("");
  --background-image-sm: url("");
  --dashboard-icon: black;
  --koii-button-icon: #bef0ed;
}

[data-theme="mint"] {
  --koii-blue: #ecfffe;
  --koii-mint: #5ed9d1;
  --koii-white: #171753;
  --koii-mint: #8989c7;
  --koii-topic-color: #171753;
  --koii-text-color: #8989c7;
  --koii-icon-bg-color: #bef0ed;
  --koii-icon-color: #4d3d8d;
  --koii-footer-color: #171753;
  --koii-footer-two-color: #6b5fa5;
  --background-image: url("/public/images/Desktop L.svg");
  --background-image-md: url("/public/images/Desktop L (1).svg");
  --background-image-sm: url("/public/images/Phone L.svg");
  --dashboard-icon: black;
  --koii-button-icon: #bef0ed;
  --koii-border-color: #6b5fa5;
  --koii-button-bg: #6b5fa5;
  --koii-button-text: #ecfffe;
  --koii-label-one-color: linear-gradient(0deg, #5ed9d1, #5ed9d1),
    linear-gradient(0deg, #171753, #171753);
  --koii-label-two-color: linear-gradient(0deg, #8989c7, #8989c7),
    linear-gradient(0deg, #bef0ed, #bef0ed);
  --koii-label-three-color: linear-gradient(0deg, #8989c7, #8989c7),
    linear-gradient(0deg, #6b5fa5, #6b5fa5);
  --koii-label-one-text-color: #bef0ed;
  --koii-label-two-text-color: #4d3d8d;
  --koii-label-three-text-color: #171753;
  --koii-label-one-border-color: #5ed9d1;
  --koii-label-two-border-color: #8989c7;
  --koii-label-three-border-color: #6b5fa5;
}

[data-theme="dark"] {
  --koii-blue: #171753;
  --koii-white: #ecfffe;
  --koii-button-white: #171753;
  --koii-mint: #5ed9d1;
  --koii-topic-color: #bef0ed;
  --koii-text-color: #ffc78f;
  --koii-icon-bg-color: #ffc78f;
  --koii-icon-color: #4d3d8d;
  --koii-footer-color: #bef0ed;
  --koii-footer-two-color: #ffc78f;
  --background-image: url("/public/images/DesktopD (1).svg");
  --background-image-md: url("/public/images/DesktopD (2).svg");
  --background-image-sm: url("/public/images/Phone D.svg");
  --dashboard-icon: white;
  --koii-button-icon: #ffc78f;
  --koii-border-color: #ffa54b;
  --koii-button-bg: #5ed9d1;
  --koii-button-text: #171753;
  --koii-label-one-color: linear-gradient(0deg, #5ed9d1, #5ed9d1),
    linear-gradient(0deg, #ffa54b, #ffa54b);
  --koii-label-two-color: linear-gradient(0deg, #5ed9d1, #5ed9d1),
    linear-gradient(0deg, #ecfffe, #ecfffe);
  --koii-label-three-color: #ffa54b;
  --koii-label-one-text-color: #171753;
  --koii-label-two-text-color: #171753;
  --koii-label-three-text-color: #171753;
  --koii-label-one-border-color: #ffa54b;
  --koii-label-two-border-color: #5ed9d1;
  --koii-label-three-border-color: #ffa54b;
}

[data-theme="gradient"] {
  --koii-blue: #171753;
  --koii-white: #ecfffe;
  --koii-button-white: #ecfffe;
  --koii-mint: #5ed9d1;
  --koii-topic-color: #171753;
  --koii-text-color: #171753;
  --koii-icon-bg-color: #8989c7;
  --koii-icon-color: #171753;
  --background-image: url("/public/images/bg_create_gradient.svg");
  --background-image-md: var(--background-image);
  --background-image-sm: var(--background-image);
  --koii-footer-color: #171753;
  --koii-footer-two-color: #ecfffe;
  --dashboard-icon: white;
  --koii-button-icon: #8989c7;
  --koii-border-color: #ecfffe;
  --koii-button-bg: #ecfffe;
  --koii-button-text: #353570;
  --koii-label-one-color: linear-gradient(0deg, #5ed9d1, #5ed9d1),
    linear-gradient(0deg, #ecfffe, #ecfffe);
  --koii-label-two-color: #ffee81;
  --koii-label-three-color: linear-gradient(0deg, #8989c7, #8989c7),
    linear-gradient(0deg, #6b5fa5, #6b5fa5);
  --koii-label-one-text-color: #4d3d8d;
  --koii-label-two-text-color: #353570;
  --koii-label-three-text-color: #ecfffe;
  --koii-label-one-border-color: #ecfffe;
  --koii-label-two-border-color: #9be7c4;
  --koii-label-three-border-color: #ecfffe;
}

[data-theme="gradient_two"] {
  --koii-blue: #171753;
  --koii-white: #ecfffe;
  --koii-button-white: #ecfffe;
  --koii-mint: #5ed9d1;
  --koii-topic-color: #4d3d8d;
  --koii-text-color: #353570;
  --koii-icon-bg-color: #ffa6a6;
  --koii-icon-color: #353570;
  --background-image: url("/public/images/profile-background-image.svg");
  --background-image-md: var(--background-image);
  --background-image-sm: var(--background-image);
  --koii-footer-color: #353570;
  --koii-footer-two-color: #ffee81;
  --dashboard-icon: white;
  --koii-button-icon: #8989c7;
  --koii-border-color: #ecfffe;
  --koii-button-bg: #ffee81;
  --koii-button-text: #171753;
  --koii-label-one-color: linear-gradient(0deg, #ffa6a6, #ffa6a6);
  --koii-label-two-color: linear-gradient(0deg, #ffee81, #ffee81);

  --koii-label-three-color: linear-gradient(0deg, #9be7c4, #9be7c4),
    linear-gradient(0deg, #ffee81, #ffee81);

  --koii-label-one-text-color: #4d3d8d;
  --koii-label-two-text-color: #353570;
  --koii-label-three-text-color: #ecfffe;
  --koii-label-one-border-color: #ecfffe;
  --koii-label-two-border-color: #5ed9d1;
  --koii-label-three-border-color: #ffee81;
}

[data-theme="dark_create"] {
  --koii-blue: #171753;
  --koii-white: #ecfffe;
  --koii-button-white: #171753;
  --koii-mint: #5ed9d1;
  --background-image: url("/public/images/backgrounds/bg_create_dark.svg");
  --background-image-md: var(--background-image);
  --background-image-sm: var(--background-image);
  --dashboard-icon: white;
  --koii-button-upload: #171753;
  --koii-button-upload-bg: #ffa54b;
  --koii-create-topic: #5ed9d1;
  --koii-create-text: #ecfffe;
  --koii-border-color: #ffa54b;
  --koii-radio-color: yellow;
  --koii-input-bg-color: linear-gradient(
    0deg,
    rgba(94, 217, 209, 0.1),
    rgba(94, 217, 209, 0.1)
  );
  --koii-label-one-color: linear-gradient(0deg, #5ed9d1, #5ed9d1),
    linear-gradient(0deg, #ffa54b, #ffa54b);
  --koii-label-two-color: linear-gradient(0deg, #ecfffe, #ecfffe);
  --koii-label-three-color: #ffa54b;
  --koii-label-one-text-color: #171753;
  --koii-label-two-text-color: #171753;
  --koii-label-three-text-color: #171753;
  --koii-border-theme-color: #ffa54b;
  --koii-label-one-border-color: #ffa54b;
  --koii-label-two-border-color: #5ed9d1;
  --koii-label-three-border-color: #ffa54b;
  --koii-profile-bg-color: transparent;
  --koii-profile-color: #5ed9d1;
}

[data-theme="light_create"] {
  --koii-create-topic: #4d3d8d;
  --koii-create-text: #171753;
  --koii-blue: #171753;
  --koii-mint: #5ed9d1;
  --koii-white: #171753;
  --koii-button-upload: #ecfffe;
  --koii-mint: #8989c7;
  --koii-button-upload-bg: #6b5fa5;
  --background-image: url("/public/images/backgrounds/bg_create_default.svg");
  --background-image-md: var(--background-image);
  --background-image-sm: var(--background-image);
  --dashboard-icon: black;
  --koii-border-color: #6b5fa5;
  --koii-input-bg-color: #5ed9d11a;
  --koii-radio-color: yellow;
  --koii-label-one-color: linear-gradient(0deg, #171753, #171753);
  --koii-label-two-color: linear-gradient(0deg, #bef0ed, #bef0ed);
  --koii-label-three-color: linear-gradient(0deg, #8989c7, #8989c7),
    linear-gradient(0deg, #6b5fa5, #6b5fa5);
  --koii-label-one-text-color: #bef0ed;
  --koii-label-two-text-color: #4d3d8d;
  --koii-label-three-text-color: #171753;
  --koii-border-theme-color: #6b5fa5;

  --koii-label-one-border-color: #5ed9d1;
  --koii-label-two-border-color: #8989c7;
  --koii-label-three-border-color: #6b5fa5;
  --koii-profile-bg-color: #ffffff;
  --koii-profile-color: #6b5fa5 !important;
}

[data-theme="gradient_create"] {
  --koii-create-topic: #171753;
  --koii-create-text: #171753;
  --koii-blue: #171753;
  --koii-white: #ecfffe;
  --koii-button-white: #ecfffe;
  --koii-button-upload: #ecfffe;
  --koii-button-upload-bg: #6b5fa5;
  --koii-choose-color: #5ed9d1;
  --koii-mint: #5ed9d1;
  --background-image: url("/public/images/gradient_create_background.svg");
  --background-image-md: var(--background-image);
  --background-image-sm: var(--background-image);
  --dashboard-icon: white;
  --koii-border-color: #ecfffe;
  --koii-radio-color: yellow;
  --koii-input-bg-color: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.2)
  );
  --koii-border-theme-color: #6b5fa5;
  --koii-label-one-color: linear-gradient(0deg, #5ed9d1, #5ed9d1);
  --koii-label-two-color: #ffee81;
  --koii-label-three-color: linear-gradient(0deg, #8989c7, #8989c7),
    linear-gradient(0deg, #6b5fa5, #6b5fa5);
  --koii-label-one-text-color: #4d3d8d;
  --koii-label-two-text-color: #353570;
  --koii-label-three-text-color: #ecfffe;

  --koii-label-one-border-color: #ecfffe;
  --koii-label-two-border-color: #9be7c4;
  --koii-label-three-border-color: #ecfffe;
  --koii-profile-bg-color: transparent;
  --koii-profile-color: #6b5fa5;
}

[data-theme="gradient_two_create"] {
  --koii-create-topic: #353570;
  --koii-create-text: #353570;
  --koii-blue: #353570;
  --koii-white: #ecfffe;
  --koii-button-white: #ecfffe;
  --koii-button-upload: #171753;
  --koii-button-upload-bg: #ffa6a6;
  --koii-mint: #5ed9d1;
  --background-image: url("/public/images/backgrounds/bg_create_gradient_two.svg");
  --background-image-md: var(--background-image);
  --background-image-sm: var(--background-image);
  --dashboard-icon: white;
  --koii-border-color: #ecfffe;
  --koii-radio-color: yellow;
  --koii-input-bg-color: rgba(255, 166, 166, 0.1);
  --koii-label-one-color: linear-gradient(0deg, #ffa6a6, #ffa6a6);
  --koii-label-two-color: linear-gradient(0deg, #ffee81, #ffee81);
  --koii-border-theme-color: #ecfffe;
  --koii-label-three-color: linear-gradient(0deg, #9be7c4, #9be7c4),
    linear-gradient(0deg, #ffee81, #ffee81);

  --koii-label-one-text-color: #4d3d8d;
  --koii-label-two-text-color: #353570;
  --koii-label-three-text-color: #ecfffe;

  --koii-label-one-border-color: #ecfffe;
  --koii-label-two-border-color: #5ed9d1;
  --koii-label-three-border-color: #ffee81;
  --koii-profile-bg-color: #ffa6a6;
  --koii-profile-color: #ffee81;
}

@keyframes gradient {
  0% {
    background-position: 15% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 15% 50%;
  }
}

#root {
  width: 100%;
}

body {
  margin: 0;
  font-family: "Sora", sans-serif !important;
  overflow-x: hidden;
  min-width: 100vw !important;
  min-height: 100vh !important;
  background-image: var(--background-image) !important;
  background-size: var(--background-size, cover) !important;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  animation: var(--animation) !important;
  color: var(--koii-white) !important;
  display: flex;
  justify-content: center;
  width: 100%;
}

.toggle-theme {
  position: absolute;
  top: 20px;
  right: 20px;
}

input[type="file"] {
  padding: 4px;
  margin: -4px;
  position: relative;
  outline: none;
  color: #fff;
  background-color: #171753;
  border-radius: 50px;
}
/* File Selector Button Styles */
input[type="file"]::file-selector-button {
  border-radius: 4px;
  padding: 0 25px;
  height: 40px;
  cursor: pointer;
  background-color: var(--koii-mint);
  border: 0;
  border-radius: 50px;
  box-shadow: 0px 1px 0px rgba(#000, 0.05);
  margin-right: 16px;
  width: 132px;
  color: transparent;
}

input[type="file"]:hover {
  /* background-color: var(--koii-blue); */
}

input[type="file"]:active {
  background-color: #e5e7eb;
}

/* Faked label styles and icon */
input[type="file"]::before {
  position: absolute;
  pointer-events: none;
  top: 14px;
  left: 16px;
  height: 20px;
  width: 20px;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%230964B0'%3E%3Cpath d='M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zM7 9l1.41 1.41L11 7.83V16h2V7.83l2.59 2.58L17 9l-5-5-5 5z'/%3E%3C/svg%3E");
}

input[type="file"]::after {
  position: absolute;
  pointer-events: none;
  font-weight: bold;
  top: 14px;
  left: 35px;
  font-size: 14px;
  color: var(--koii-blue);
  margin-right: 15px;
  content: "Upload File";
}

/* Handle Component Focus */
input[type="file"]:focus-within::file-selector-button,
input[type="file"]:focus::file-selector-button {
  outline: 2px solid var(--koii-blue);
  outline-offset: 2px;
}

@media screen and (max-width: 800px) {
  body[data-theme="dark"],
  body[data-theme="light"] {
    background-image: var(--background-image-md) !important;
    background-position: bottom !important;
  }
}

@media screen and (max-width: 700px) {
  body[data-theme="dark"],
  body[data-theme="light"] {
    background-image: var(--background-image-sm) !important;
    background-position: top !important;
  }
}
